<template>
  <v-app>
    <v-container class="grey lighten-5">
      <v-row no-gutters>
        <v-col cols="5">
          <v-card class="pa-2" outlined>
            <!-- DATE -->
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <!-- TIME -->
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  label="Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="time"
                full-width
              ></v-time-picker>
            </v-menu>
            <!-- TIMEZONE -->
            <v-select
              :items="zones"
              prepend-icon="mdi-timer-sand"
              v-model="zone"
              label="Origin Timezone"
            ></v-select>
            <!-- RESET -->
            <v-btn elevation="2" outlined raised @click="reset()">Reset</v-btn>
          </v-card>
        </v-col>
        <v-col cols="7">
          <v-card class="pa-2 pl-8" elevation="0">
            <v-card class="pa-2 mb-2" outlined v-for="c in conversions" :key="c.name">
              <v-container class="pa-2">
                <v-row no-gutters>
                  <v-col cols="2"><p class="mb-0 font-weight-black">{{c.zone}}</p></v-col>
                  <v-col cols="6"><p class="mb-0">{{c.time}}</p></v-col>
                  <v-col cols="4"><p class="mb-0">{{c.name}}</p></v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { DateTime } from 'luxon';
import { zones } from 'tzdata';

export default {
  data: () => ({
    date: DateTime.now().toFormat('yyyy-MM-dd'),
    time: DateTime.now().toFormat('HH:mm'),
    zone: DateTime.now().zoneName,
    menu1: false,
    menu2: false,
    zones: Object.entries(zones)
      // eslint-disable-next-line no-unused-vars
      .filter(([zoneName, v]) => Array.isArray(v))
      // eslint-disable-next-line no-unused-vars
      .map(([zoneName, v]) => zoneName)
      .filter((tz) => DateTime.local().setZone(tz).isValid),
    select: null,
    targets: [
      'America/Los_Angeles',
      'America/Chicago',
      'America/New_York',
      'UTC',
      'Europe/Paris',
      'Europe/Moscow',
      'Indian/Maldives',
      'Asia/Jakarta',
      // 'Asia/Manila',
      'Asia/Tokyo',
    ],
    conversions: null,
  }),
  watch: {
    date() {
      this.recalculate();
    },
    time() {
      this.recalculate();
    },
    zone() {
      this.recalculate();
    },
  },
  methods: {
    reset() {
      this.date = DateTime.now().toFormat('yyyy-MM-dd');
      this.$nextTick(() => {
        this.time = DateTime.now().toFormat('HH:mm');
        this.$nextTick(() => {
          this.zone = DateTime.now().zoneName;
        });
      });
    },
    recalculate() {
      this.select = DateTime.fromString(`${this.date} ${this.time}`, 'yyyy-MM-dd HH:mm', { zone: this.zone });
      const conversions = [];
      this.targets.forEach((target) => {
        const conversion = this.select.setZone(target);
        conversions.push({
          name: target,
          zone: conversion.toFormat('ZZZZ'),
          time: conversion.toLocaleString(DateTime.DATETIME_MED),
        });
      });
      this.conversions = conversions;
    },
  },
  mounted() {
    this.reset();
    this.recalculate();
  },
};
</script>

<style lang="scss" scoped>
.height-40 {
  line-height:40px !important;
}
</style>
